<template>
    <div class="card rounded-0 border-0 p-0">
        <div class="card-body">
            <spinner :isLoading="isLoading" size="sm" color="secondary"/>
            <div v-if="!isFileUploadStepDone && !isLoading" class="alert alert-orange mt-3">{{ __('survey.to_see_results') }}</div>
            <div class="my-4">
                <upload-files
                    v-if="surveyType === SURVEY_TYPES.ADVANCED"
                    :isLoading="isLoading"
                    :isFileUploadStepDone="isFileUploadStepDone"
                    :neighborhood="neighborhood"
                    :requiredFiles="requiredFiles"
                    :reload="init"
                />
            </div>
            <result v-if="isFileUploadStepDone && !isLoading" :neighborhood="neighborhood" :survey-type="surveyType"/>
        </div>
    </div>
</template>

<script>
import Result from "./Result";
import UploadFiles from "./UploadFiles";
import Spinner from "../../Spinner";

export default {
    components: {Spinner, Result, UploadFiles},
    props: [
        'neighborhood',
        'surveyType',
    ],
    data() {
        return {
            isLoading: true,
            files: [],
            requiredFiles: [],
            SURVEY_TYPES: {
                BASIC: 'basic',
                ADVANCED: 'advanced',
            },
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(callback = null) {
            await Promise.all([
                this.axios.post('/api/survey/phase-1/files/required',
                    {
                        neighborhood: this.neighborhood,
                        surveyType: this.surveyType,
                    })
                    .then(response => {
                        if (!response.data.success) {
                            return;
                        }
                        this.requiredFiles = response.data.required;
                        if (callback) {
                            callback();
                        }
                    }),
            ]).finally(() => {
                this.isLoading = false;
            });
        },
    },
    computed: {
        isFileUploadStepDone() {
            return this.requiredFiles.length > 0 && this.surveyType === this.SURVEY_TYPES.ADVANCED
                ? !this.requiredFiles.find(el => el.files.length === 0 && !el.ready)
                : true;
        },
    },
}
</script>
