<template>
    <p>{{ __('survey.formats', {max: 3}) }}: <strong>.jpeg</strong>, <strong>.jpg</strong>,
        <strong>.png</strong>,
        <strong>.pdf</strong>, <strong>.doc</strong>, <strong>.docx</strong>.
    </p>

    <spinner :isLoading="isLoading" size="sm" color="secondary"/>

    <div class="row">
        <div v-for="upload in requiredFiles" class="col-12 col-md-6 p-1">
            <div class="card">
                <div class="card-header">
                    <span class="fw-bold">{{ upload.title }}<i v-if="upload.ready" class="icofont-check text-success"></i></span>
                </div>
                <div class="card-body">
                    <div class="mb-3" v-if="upload.files.length === 0">
                        <div class="pretty p-icon p-curve p-pulse p-bigger">
                            <input type="checkbox" v-model="upload.ready"/>
                            <div class="state p-success fw-light">
                                <i class="icon icofont-check"></i>
                                <label><span class="fw-light text-muted">{{ __('survey.doesnt_apply') }}</span></label>
                            </div>
                        </div>
                    </div>
                    <div v-if="!upload.ready">
                        <div v-if="upload.files.length > 0" class="mb-3">
                            <div v-for="file in upload.files" class="mt-1 p-2 mb-0 d-flex justify-content-between align-content-center w-100" style="border: dashed 1px;">
                                <p class="mb-0">
                                    <i class="icofont-file-alt text-secondary"></i>
                                    <span class="ms-2">{{ $helpers.characters(file.name, 20) }}</span>
                                </p>
                                <button class="btn btn-sm btn-link" @click="this.confirmRemoveFile(file.id, upload.key)">
                                    <i class="icofont-bin"></i>
                                </button>
                            </div>
                        </div>
                        <div v-if="this.fileLoadingKey === upload.key">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                        </div>
                        <div v-else-if="upload.files.length < 3">
                            <input type="file" @change="uploadFile(upload.key)" :ref="upload.key">
                        </div>
                    </div>
                    <div class="alert alert-danger mt-3" v-if="this.fileLoadingMessage?.key === upload.key">
                        {{ this.fileLoadingMessage.message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {createToast as toast} from "mosha-vue-toastify";
import Spinner from "../../Spinner";

export default {
    components: {Spinner},
    props: [
        'requiredFiles',
        'isLoading',
        'neighborhood',
        'reload',
        'isFileUploadStepDone',
    ],
    data() {
        return {
            fileLoadingKey: null,
            fileLoadingMessage: null,
        }
    },
    methods: {
        uploadFile(key) {
            this.resetError();

            if (this.fileLoadingKey) {
                return;
            }

            const acceptedFormats = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];
            const file = this.$refs[key][0].files[0];

            if (!acceptedFormats.includes(file.type)) {
                this.$refs[key][0].value = '';
                this.handleError('Sólo se aceptan archivos con formato: .jpeg, .jpg, .png, .pdf, .doc, .docx', key);
                return;
            }

            if (file.size > 4000000) {
                this.$refs[key][0].value = '';
                this.handleError('Sólo se aceptan archivos de no más de 4MB.', key);
                return;
            }

            this.fileLoadingKey = key;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('key', key);
            formData.append('neighborhood', this.neighborhood);
            formData.append('round', '1');

            this.axios.post('/api/survey/phase-1/files/upload',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
                .then(response => {
                    if (!response.data.success) {
                        this.fileLoadingKey = null;
                        this.handleError(response.data.message, key);
                        return;
                    }
                    this.reload(this.resetUploads);
                    toast({
                        title: this.__('survey.done'),
                        description: this.__('general.file_uploaded'),
                    }, {
                        type: 'success',
                    });
                })
                .catch((e) => {
                    this.handleError(e.message, key);
                    this.fileLoadingKey = null;
                });
        },
        confirmRemoveFile(id, key) {
            if (confirm('¿Está seguro que quiere eliminar este archivo?')) {
                this.removeFile(id, key);
            }
        },
        removeFile(file_id, key) {
            this.fileLoadingKey = key;
            this.axios.post('/api/survey/phase-1/files/remove', {file_id})
                .then(response => {
                    if (!response.data.success) {
                        this.fileLoadingKey = null;
                        this.handleError('Ocurrió un error. Intente nuevamente más tarde.', key);
                        return;
                    }
                    this.reload(this.resetUploads);
                    toast({
                        title: this.__('survey.done'),
                        description: this.__('general.file_removed'),
                    }, {
                        type: 'success',
                    });
                })
                .catch((e) => {
                    this.handleError(e.message, key);
                    this.fileLoadingKey = null;
                });
        },
        resetUploads() {
            this.fileLoadingKey = null;
        },
        handleError(message = null, key) {
            this.fileLoadingMessage = {key, message};
        },
        resetError() {
            this.fileLoadingMessage = null;
        },
    },
}
</script>
