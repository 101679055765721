import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Skeletor } from 'vue-skeletor';
import * as Sentry from "@sentry/browser";
import SurveyPhaseOne from './components/Survey/PhaseOne/Index';
import Spinner from './components/Spinner';
import SurveyPhaseTwo from './components/Survey/PhaseTwo/Index';
import SurveyPhaseThree from './components/Survey/PhaseThree/Index';
import CompleteNeighborhood from './components/Survey/PhaseOne/CompleteNeighborhood';

const app = createApp({});
const SwiperDefaults = require('swiper');
const translations = require('./trans');

Sentry.init({
    dsn: process.env.MIX_SENTRY_DSN_PUBLIC,
    debug: false,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.5,
});

window.Swiper = SwiperDefaults.Swiper;
Swiper.use([SwiperDefaults.Navigation, SwiperDefaults.Pagination]);

window.Parallax = require('./plugins/parallax.min');
window.intlTelInput = require('intl-tel-input');

axios.defaults.headers.common = {
        Authorization: 'Bearer ' + Laravel.apiToken,
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': Laravel.csrfToken,
};

app.mixin(translations);
app.use(VueAxios, axios);

app.component(Skeletor.name, Skeletor);
app.component('survey-phase-one', SurveyPhaseOne);
app.component('survey-phase-two', SurveyPhaseTwo);
app.component('survey-phase-three', SurveyPhaseThree);
app.component('complete-neighborhood', CompleteNeighborhood);
app.component('spinner', Spinner);

app.config.globalProperties.$helpers = {
    sanitizeHTML(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;

        return tmp.textContent || tmp.innerText || "";
    },
    words(value, size) {
        if (!value) {
            return '';
        }

        const result = this.sanitizeHTML(value).split(' ');
        const resultTrimmed = result.slice(0, size).join(' ');

        return resultTrimmed + (result.length > size ? '...' : '');
    },
    characters(value, size) {
        if (!value) {
            return '';
        }

        const result = this.sanitizeHTML(value).substring(0, size);

        return result + (this.sanitizeHTML(value).length > size ? '...' : '');
    }
}

app.mount('#app');
