<template>
    <form class="card" @submit="onSubmit">
        <div v-if="isTemporaryLocked">
            <temporary-locked :neighborhood="neighborhood" :phase="2"/>
        </div>
        <div v-else-if="isCompleted">
            <completed :neighborhood="neighborhood" :phase="2"/>
        </div>
        <div v-else>
            <progress-bar :progress-percentage="progressPercentage"/>
            <div class="d-lg-flex align-items-center">
                <div class="col-12 col-lg-8">
                    <div class="card-body p-5">

                        <!-- loading skeleton-->
                        <div v-if="isLoading" class="py-3">
                            <Skeletor width="50%" height="40" style="border-radius: 10px;"/>
                            <Skeletor width="80%" style="border-radius: 10px;"/>
                            <Skeletor width="100%" height="100" class="mt-3" style="border-radius: 10px;"/>
                        </div>

                        <!-- school info header-->
                        <div v-else class="py-3">
                            <div class="py-2 text-b">
                                <h5 class="text-primary mb-2">{{ __('survey.school') }}: {{ school?.name }}</h5>
                                <h6 class="mb-4 text-muted">{{ __('home.survey') }} v. {{ version }} | {{ __('home.phase') }}: 2 | {{ __('survey.reference_key') }}: <small class="fw-light text-secondary">ID:{{ question?.id }}</small></h6>
                                <p class="fs-3 lh-base"> {{ question?.text }}</p>
                            </div>
                        </div>

                        <!-- answer being processed-->
                        <div v-if="isProcessing" class="py-2 d-flex justify-content-center align-items-center">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">{{ __('survey.processing_answer') }}</span>
                            </div>
                            <p class="mb-0 ms-2 text-secondary">{{ __('survey.processing_answer') }}...</p>
                        </div>

                        <!-- question-->
                        <div v-else-if="question">
                            <!-- FILE -->
                            <div v-if="question.type === types.QUESTION_TYPE_FILE">
                                <div v-if="answerFile.length > 0 && !isRemovingFile" class="mb-3">
                                    <div v-for="file in answerFile" class="mt-1 p-2 mb-0 d-flex justify-content-between align-content-center w-100" style="border: dashed 1px;">
                                        <p class="mb-0">
                                            <i class="icofont-file-alt text-secondary"></i> <span class="ms-2">{{ $helpers.characters(file.name, 50) }}</span>
                                        </p>
                                        <button class="btn btn-sm btn-link" type="button" @click="confirmRemoveFile(file.id)">
                                            <i class="icofont-bin"></i>
                                        </button>
                                    </div>
                                </div>
                                <div v-if="isLoadingFile">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                                <div v-else-if="answerFile.length <= 10">
                                    <input type="file" @change="uploadFile" ref="answer-file" :accept="acceptedFormats">
                                </div>
                                <hr>
                                <p class="mt-2">{{ __('survey.formats', {max: 10}) }}: <strong>.jpeg</strong>, <strong>.jpg</strong>, <strong>.png</strong>, <strong>.pdf</strong>, <strong>.doc</strong>, <strong>.docx</strong>.</p>
                            </div>

                            <!-- REORDER -->
                            <div v-if="question.type === types.QUESTION_TYPE_REORDER">
                                <ul class="drag-ul ps-0">
                                    <draggable
                                        v-model="this.question.options"
                                        item-key="index"
                                        @start="drag=true"
                                        @end="drag=false"
                                    >
                                        <template #item="{element, index}">
                                            <li class="list-group-item-action d-flex drag-element justify-content-start align-items-center">
                                                <span class="badge bg-secondary rounded-pill">{{ index + 1 }}</span>
                                                <span class="ms-3">{{ element }}</span>
                                            </li>
                                        </template>
                                    </draggable>
                                </ul>
                            </div>

                            <!-- TEXT -->
                            <div v-if="question.type === types.QUESTION_TYPE_TEXT">
                                <textarea autofocus @keydown.enter="onSubmit" ref="answer-text" v-model="answerText" cols="30" rows="10" class="form-control"></textarea>
                            </div>

                            <!-- MULTIPLE -->
                            <div v-if="question.type === types.QUESTION_TYPE_MULTIPLE">
                                <div v-for="(option, index) in question.options" class="pretty p-icon p-curve p-pulse p-bigger mb-3 me-5">
                                    <input v-model="answerMultiple" :value="index" type="checkbox"/>
                                    <div class="state p-success fs-5">
                                        <i class="icon icofont-check"></i>
                                        <label><small class="fw-light text-muted">{{ option }}</small></label>
                                    </div>
                                </div>
                            </div>

                            <!-- PERCENTAGE -->
                            <div v-if="question.type === types.QUESTION_TYPE_PERCENTAGE">
                                <div class="input-group">
                                    <input v-model="answerPercentage" type="number" min="0" max="100" class="form-control" ref="answer-percentage">
                                    <span class="input-group-text"><i class="la la-percent"></i></span>
                                </div>
                            </div>

                        </div>

                        <div class="mt-5">
                            <div>
                                <div v-if="isLoading" class="d-flex justify-content-between">
                                    <Skeletor width="20%" height="40px" style="border-radius: 10px;"/>
                                    <Skeletor width="20%" height="40px" style="border-radius: 10px;"/>
                                </div>
                                <div v-else class="d-flex justify-content-between">
                                    <button :disabled="!canGoBack || isProcessing" v-on:click="goBack" type="button" class="d-flex btn btn-light align-items-center justify-content-center">
                                        <i class="icofont-arrow-left"></i> {{ __('survey.previous') }}
                                    </button>
                                    <button type="submit" :disabled="!isAnswerReady" class="d-flex btn btn-secondary align-items-center justify-content-center">
                                        <span class="text-light">{{ __('survey.next') }}</span>
                                        <i class="icofont-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-lg-4 mb-lg-0">
                    <div class="p-3 survey-sidebar">
                        <div v-if="isLoading">
                            <Skeletor width="50%" style="border-radius: 10px;"/>
                            <Skeletor width="100%" height="100" class="mt-3" style="border-radius: 10px;"/>
                        </div>
                        <div v-else class="p-3">
                            <p class="fw-bold mb-1"> {{ __('survey.' + neighborhood + '.particular.' + particular + '.title') }}</p>
                            <p class="mb-1" style="font-size: 13px !important;" v-html="$helpers.words(__('survey.' + neighborhood + '.particular.' + particular + '.description'), 50)"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="small text-center"><i class="icofont-warning-alt"></i> {{ __('survey.bottom_message') }}</div>
            </div>
        </div>
    </form>
</template>

<script>
import TemporaryLocked from "../TemporaryLocked";
import Completed from "../Completed";
import ProgressBar from "../ProgressBar";
const QUESTION_TYPE_TEXT = 'text';
const QUESTION_TYPE_FILE = 'file';
const QUESTION_TYPE_MULTIPLE = 'multiple';
const QUESTION_TYPE_REORDER = 'reorder';
const QUESTION_TYPE_PERCENTAGE = 'percentage';
import {createToast as toast} from "mosha-vue-toastify";
import draggable from 'vuedraggable';
export default {
    components: {ProgressBar, Completed, TemporaryLocked, draggable},
    props: [
        'api_token',
        'neighborhood',
    ],
    data() {
        return {
            version: null,
            level: null,
            neighborhoods: [],
            particulars: [],
            files: [],
            particular: null,
            school: {},
            progressPercentage: 0,
            questionsCount: 0,
            question: {},
            isCompleted: false,
            isLoadingFile: false,
            isRemovingFile: false,
            answerText: null,
            answerFile: [],
            answerMultiple: [],
            answerPercentage: null,
            answerReady: null,
            hasAnswerReady: false,
            canGoBack: false,
            isLoading: true,
            isProcessing: false,
            isTemporaryLocked: false,
            errorMsg: null,
            types: {
                QUESTION_TYPE_TEXT: QUESTION_TYPE_TEXT,
                QUESTION_TYPE_FILE: QUESTION_TYPE_FILE,
                QUESTION_TYPE_MULTIPLE: QUESTION_TYPE_MULTIPLE,
                QUESTION_TYPE_REORDER: QUESTION_TYPE_REORDER,
                QUESTION_TYPE_PERCENTAGE: QUESTION_TYPE_PERCENTAGE,
            },
            acceptedFormats: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'],
            surveyType: 'advanced',
            drag: false,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.axios.post('/api/survey/phase-2/start',
                {
                    neighborhood: this.neighborhood,
                    surveyType: this.surveyType,
                })
                .then(response => {
                    if (!response.data.success) {
                        this.handleError(response.data.message);
                        this.isLoading = false;
                        return;
                    }

                    this.version = response.data.version;
                    this.level = response.data.level;
                    this.neighborhoods = response.data.neighborhoods;
                    this.particulars = response.data.particulars;
                    this.particular = response.data.particular;
                    this.school = response.data.school;
                    this.question = response.data.question;
                    this.canGoBack = response.data.canGoBack;
                    this.progressPercentage = response.data.progressPercentage;
                    this.questionsCount = response.data.questionsCount;
                    this.isCompleted = response.data.isCompleted;
                    this.isTemporaryLocked = response.data.isTemporaryLocked;
                    this.setAnswer(response.data.answer);

                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.handleError(error.message);
                });
        },
        onSubmit(e) {
            this.errorMsg = null;
            e.preventDefault();

            if (this.isProcessing || this.getCurrentAnswer() === null) {
                this.handleError('No answer provided.');
                this.isProcessing = false;

                return;
            }

            this.isProcessing = true;
            this.axios.post('/api/survey/phase-2/answer',
                {
                    surveyType: this.surveyType,
                    question_id: this.question.id,
                    answer: this.getCurrentAnswer(),
                    version: this.version,
                    questions_count: this.questionsCount,
                })
                .then(response => {
                    this.isProcessing = false;
                    if (!response.data.success) {
                        return this.handleError(response.data.message);
                    }
                    this.loadNextAnswer(response.data);
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.handleError(error.message);
                });
        },
        uploadFile() {
            if (this.isLoadingFile || this.isLoading) {
                return;
            }

            const file = this.$refs['answer-file'].files[0];

            if (!this.acceptedFormats.includes(file.type)) {
                this.$refs['answer-file'].value = '';
                this.handleError('Sólo se aceptan archivos con formato: .jpeg, .jpg, .png, .pdf, .doc, .docx');
                return;
            }

            if (file.size > 4000000) {
                this.$refs['answer-file'].value = '';
                this.handleError('Sólo se aceptan archivos de no más de 4MB.', );
                return;
            }

            this.isLoadingFile = true;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('question_id', this.question.id);

            this.axios.post('/api/survey/phase-2/files/upload',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    if (!response.data.success) {
                        this.isLoadingFile = false;
                        this.handleError(response.data.message);
                        return;
                    }
                    this.answerFile = response.data.answer;
                    this.isLoadingFile = false;
                    toast({
                        title: this.__('survey.done'),
                        description: this.__('general.file_uploaded'),
                    }, {
                        type: 'success',
                    });
                })
                .catch((e) => {
                    this.handleError(e.message);
                    this.isLoadingFile = false;
                });
        },
        confirmRemoveFile(id) {
            if (confirm('¿Está seguro que quiere eliminar este archivo?')) {
                this.removeFile(id);
            }
        },
        removeFile(file_id) {
            this.isRemovingFile = true;
            this.axios.post('/api/survey/phase-2/files/remove', { file_id })
                .then(response => {
                    this.isRemovingFile = false;
                    if (!response.data.success) {
                        this.handleError('Ocurrió un error. Intente nuevamente más tarde.');
                        return;
                    }
                    this.answerFile = response.data.answer;
                    toast({
                        title: this.__('survey.done'),
                        description: this.__('general.file_removed'),
                    }, {
                        type: 'success',
                    });
                })
                .catch((e) => {
                    this.isRemovingFile = false;
                    this.handleError(e.message);
                });
        },
        goBack() {
            this.errorMsg = null;
            if (this.isProcessing) {
                return;
            }
            this.isProcessing = true;
            this.axios.post('/api/survey/phase-2/go-back',
                {
                    question_id: this.question.id,
                    questions_count: this.questionsCount,
                    version: this.version,
                })
                .then(response => {
                    this.isProcessing = false;
                    if (!response.data.success) {
                        this.canGoBack = false;
                        return this.handleError(response.data.message);
                    }
                    this.loadNextAnswer(response.data);
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.canGoBack = false;
                    this.handleError(error.message);
                });
        },
        getCurrentAnswer() {
            let answer;
            switch (this.question?.type) {
                case this.types.QUESTION_TYPE_FILE:
                    answer = this.answerFile.length > 0 ? this.answerFile : null;
                    break;
                case this.types.QUESTION_TYPE_TEXT:
                    answer = this.answerText;
                    break;
                case this.types.QUESTION_TYPE_MULTIPLE:
                    answer = this.answerMultiple;
                    break;
                case this.types.QUESTION_TYPE_REORDER:
                    answer = this.question.options;
                    break;
                case this.types.QUESTION_TYPE_PERCENTAGE:
                    answer = this.answerPercentage;
                    break;
                default:
                    answer = null;
                    break;
            }

            return answer;
        },
        /**
         *
         * @param {Boolean} data.isCompleted
         * @param {String} data.particular
         * @param {Object} data.question
         * @param {Boolean} data.canGoBack
         * @param {Number} data.progressPercentage
         * @param {Number} data.level
         * @param {String|Null} data.answer
         */
        loadNextAnswer(data) {
            this.isCompleted = data.isCompleted;
            this.particular = data.particular;
            this.question = data.question;
            this.canGoBack = data.canGoBack;
            this.progressPercentage = data.progressPercentage;
            this.level = data.level;

            this.setAnswer(data.answer);
        },
        setAnswer(answer) {
            this.answerFile = [];
            this.answerText = null;
            this.answerMultiple = [];

            switch (this.question?.type) {
                case this.types.QUESTION_TYPE_FILE:
                    this.answerFile = answer;
                    break;
                case this.types.QUESTION_TYPE_TEXT:
                    this.answerText = answer;
                    break;
                case this.types.QUESTION_TYPE_MULTIPLE:
                    this.answerMultiple = answer ? answer : [];
                    break;
                case this.types.QUESTION_TYPE_REORDER:
                    this.question.options = answer ? answer : this.question.options;
                    break;
                case this.types.QUESTION_TYPE_PERCENTAGE:
                    this.answerPercentage = answer;
                    break;
                default:
                    break;
            }
            this.focusOnText();
        },
        handleError (msg) {
            toast({
                title: 'Error',
                description: msg ?? 'An error occurred...',
            }, {
                type: 'danger',
            });
        },
        focusOnText() {
            this.$nextTick(() => {
                if (this.question.type === this.types.QUESTION_TYPE_TEXT) {
                    this.$refs['answer-text'].focus();
                }
                if (this.question.type === this.types.QUESTION_TYPE_PERCENTAGE) {
                    this.$refs['answer-percentage'].focus();
                }
            });
        }
    },
    computed: {
        isAnswerReady() {
            return !this.isProcessing && !this.isRemovingFile && !this.isLoadingFile && this.getCurrentAnswer() !== null;
        }
    },
}
</script>
