const WOW = require("wowjs");
import Parallax from '../plugins/parallax.min';

(function ($) {
    ('use strict');

    /*----------------------------------------*/
    /*  Check if element exists
/*----------------------------------------*/
    $.fn.elExists = function () {
        return this.length > 0;
    };

    /*--
        Custom script to call Background
        Image from html data attribute
    -----------------------------------*/
    $('[data-bg-image]').each(function () {
        var $this = $(this),
            $image = $this.data('bg-image');
        $this.css('background-image', 'url(' + $image + ')');
    });

    /*----------------------------------------*/
    /*  WOW
/*----------------------------------------*/
    new WOW.WOW();

    /*---------------------------------------
		Header Sticky
---------------------------------*/
    var windows = $(window);
    var screenSize = windows.width();
    var sticky = $('.header-sticky');
    windows.on('scroll', function () {
        var scroll = windows.scrollTop();
        var headerHeight = sticky.height();

        if (screenSize >= 320 && !sticky.hasClass('not-home')) {
            if (scroll < headerHeight) {
                sticky.removeClass('is-active');
            } else {
                sticky.addClass('is-active');
            }
        }
    });

    /*----------------------------------------*/
    /*  OnePage Nav
/*----------------------------------------*/

    var top_offset = $('.onepage-nav').height() - 60;
    $('.onepage-nav ul').onePageNav({
        currentClass: 'active',
        scrollOffset: top_offset,
    });

    /*----------------------------------------*/
    /*  HasSub Item
/*----------------------------------------*/
    $('.hassub-item li.hassub a').on('click', function () {
        $(this).removeAttr('href');
        var element = $(this).parent('li');
        if (element.hasClass('open')) {
            element.removeClass('open');
            element.find('li').removeClass('open');
            element.find('ul').slideUp();
        } else {
            element.addClass('open');
            element.children('ul').slideDown();
            element.siblings('li').children('ul').slideUp();
            element.siblings('li').removeClass('open');
            element.siblings('li').find('li').removeClass('open');
            element.siblings('li').find('ul').slideUp();
        }
    });

    /*----------------------------------------*/
    /*  Seeting Buton
/*----------------------------------------*/
    $('.setting-btn').on('click', function (e) {
        e.preventDefault();
        $('.setting-body').slideToggle();
    });

    /*------------------------------------
	    Magnific Popup
	    ------------------------------------- */
    if ($('.popup-vimeo').elExists()) {
        $('.popup-vimeo').magnificPopup({
            type: 'iframe',
            disableOn: function () {
                if ($(window).width() < 600) {
                    return false;
                }
                return true;
            },
        });
    }

    /*------------------------------------
	    Extbot Parallax
	    ------------------------------------- */
    if ($('.scene').elExists()) {
        $('.scene').each(function () {
            new Parallax($(this)[0]);
        });
    }

    /*----------------------------------------*/
    /*  AOS
/*----------------------------------------*/
    if ($('[data-aos]').length) {
        // AOS.init({
        //     once: true,
        // });
    }

    /*--------------------------------
    Scroll To Top
-------------------------------- */
    function scrollToTop() {
        var $scrollUp = $('.scroll-to-top'),
            $lastScrollTop = 0,
            $window = $(window);

        $window.on('scroll', function () {
            var topPos = $(this).scrollTop();
            if (topPos > $lastScrollTop) {
                $scrollUp.removeClass('show');
            } else {
                if ($window.scrollTop() > 200) {
                    $scrollUp.addClass('show');
                } else {
                    $scrollUp.removeClass('show');
                }
            }
            $lastScrollTop = topPos;
        });

        $scrollUp.on('click', function (evt) {
            $('html, body').animate(
                {
                    scrollTop: 0,
                },
                600
            );
            evt.preventDefault();
        });
    }

    scrollToTop();
})(jQuery);
