<template>
    <form class="card" @submit="onSubmit">
        <div class="card-header px-4 py-3">
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="my-0 text-secondary">Guía de Análisis para Resultados</h5>
                <a class="btn btn-sm btn-secondary"
                   :href="'/survey/phase-3/' + neighborhood">
                    <i class=" la la-arrow-left"></i> {{ __('survey.back_to_results') }}
                </a>
            </div>
        </div>
        <progress-bar :progress-percentage="progressPercentage"/>
        <div class="card-body px-5">
            <div v-if="this.progressPercentage === 100" class="text-center p-5">
                <i class="la la-check bg-success text-light rounded-circle p-2 fs-2"></i>
                <h4 class="my-3">¡Adelante!</h4>
                <h4 class="fw-light">Ya está encaminado en el proceso que los llevará a ser agentes de transformación en las vidas de sus estudiantes y los demás miembros de su comunidad educativa.</h4>
                <div class="btn-group" role="group">
                    <a class="btn btn-sm btn-primary mt-4"
                       :href="'/survey/phase-3/' + neighborhood">
                        <i class=" la la-arrow-left"></i> {{ __('survey.back_to_results') }}
                    </a>
                    <a class="btn btn-sm btn-outline-primary mt-4"
                       target="_blank"
                       :href="'/survey/phase-3/guide-download/' + neighborhood">
                        <i class="la la-download"></i> {{ __('phase_3.download_guide') }}
                    </a>
                </div>
            </div>

            <div v-else>

                <!-- loading skeleton-->
                <div v-if="isLoading" class="py-3">
                    <Skeletor width="50%" height="40" style="border-radius: 10px;"/>
                    <Skeletor width="80%" style="border-radius: 10px;"/>
                    <Skeletor width="100%" height="100" class="mt-3" style="border-radius: 10px;"/>
                </div>

                <!-- school info header-->
                <div v-else class="py-3">
                    <div class="py-2 text-b">
                        <h5 class="text-primary mb-2">{{ __('survey.school') }}: {{ school?.name }}</h5>
                        <h6 class="mb-0 text-muted">{{ __('home.survey') }} v. {{ version }} | {{ __('home.phase') }}: 3 | {{ __('survey.reference_key') }}:
                            <small class="fw-light text-secondary text-uppercase">ID:{{ question?.slug }}</small>
                        </h6>
                        <hr class="mb-0">
                    </div>
                </div>

                <!-- answer being processed-->
                <div v-if="isProcessing" class="py-2 d-flex justify-content-center align-items-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">{{ __('survey.processing_answer') }}</span>
                    </div>
                    <p class="mb-0 ms-2 text-secondary">{{ __('survey.processing_answer') }}...</p>
                </div>

                <!-- question/answer-->
                <div v-if="!isLoading && !isProcessing">

                    <div v-if="question.category === questionsCategories.CATEGORY_IDENTIFICATION">
                        <!-- question-->
                        <p class="fs-3 lh-base" v-html="question.text"></p>
                        <!-- answer-->
                        <textarea autofocus v-model="answerIdentification" class="form-control" name="" id="" cols="30" rows="5">{{ answerIdentification }}</textarea>
                    </div>

                    <div v-if="question.category === questionsCategories.CATEGORY_PARTICULARS_UNDERSTANDING">
                        <!-- explanation-->
                        <p style="line-height: 20px;" v-html="question.explanation"></p>
                        <!-- questions-->
                        <div class="mb-4" v-html="question.particularDefinition"></div>
                        <!-- answers-->
                        <div v-for="(currentQuestion, index) in question.particularQuestions" class="mb-4">
                            <p class="fs-5 lh-base" v-html="currentQuestion"></p>
                            <textarea autofocus v-model="answerParticularsUnderstanding[index]" class="form-control" name="" id="" cols="30" rows="5"></textarea>
                        </div>
                    </div>

                    <div v-if="question.category === questionsCategories.CATEGORY_ACHIEVED_WITHOUT_EVIDENCE">
                        <!-- explanation-->
                        <p>{{ question.explanation }}</p>
                        <!--text-->
                        <p>{{ question.text }}</p>
                        <!-- questions/answers-->
                        <div v-for="(objectives, particular) in question.particularQuestions" class="mt-5">
                            <h5 class="mb-1 text-secondary ms-2"> {{ this.particularTitle(particular) }}</h5>
                            <table class="table table-striped table-hover">
                                <thead>
                                <tr>
                                    <th style="width: 35%;">{{ __('survey.objective') }}</th>
                                    <th style="width: 55%;">{{ __('survey.final_results') }}</th>
                                    <th style="width: 10%;" class="text-center">Select</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(objective, index) in objectives">
                                    <td class="w-20 align-middle">{{ objective.text }}</td>
                                    <td class="w-70 align-middle">
                                        <div>
                                            <ul>
                                                <li v-for="result in objective.results">{{ result }}</li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td class="w-10 align-middle text-center">
                                        <input v-model="answerAchievedWithoutEvidence[particular][index]" type="checkbox">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-if="question.category === questionsCategories.CATEGORY_CONFIRM_ACHIEVED_RESULTS">
                        <!--text-->
                        <p>{{ question.text }}</p>

                        <div v-if="Object.keys(this.question.particularQuestions.yes).length > 0">
                            <h5 class="mb-3 mt-5 text-secondary">{{ __('survey.achieved_without_evidence') }}:</h5>
                            <div class="alert alert-secondary" v-for="(objectives, particular) in this.question.particularQuestions.yes">
                                <p class="mb-1">
                                    <strong>Particular:</strong> {{ __('survey.' + neighborhood + '.particular.' + particular + '.title') }}
                                </p>
                                <div v-for="objective in objectives">
                                    <p class="mb-1"><strong>{{ __('survey.objective') }}:</strong> {{ objective.text }}
                                    </p>
                                    <p class="mb-0"><strong>{{ __('survey.final_results') }}:</strong></p>
                                    <ul v-for="objective in objective.results" class="ms-3">
                                        <li class="text-dark">{{ objective }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div v-if="Object.keys(this.question.particularQuestions.no).length > 0">
                            <h5 class="mb-3 mt-5 text-warning">{{ __('survey.objectives_to_achieve') }}:</h5>
                            <div class="alert alert-warning" v-for="(objectives, particular) in this.question.particularQuestions.no">
                                <p class="mb-1">
                                    <strong>Particular:</strong> {{ __('survey.' + neighborhood + '.particular.' + particular + '.title') }}
                                </p>
                                <div v-for="objective in objectives">
                                    <p class="mb-1"><strong>{{ __('survey.objective') }}:</strong> {{ objective.text }}
                                    </p>
                                    <p class="mb-0"><strong>{{ __('survey.final_results') }}:</strong></p>
                                    <ul v-for="objective in objective.results" class="ms-3">
                                        <li class="text-dark">{{ objective }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div v-if="question.category === questionsCategories.CATEGORY_INITIAL_ORDER">
                        <p>{{ question.text }}</p>

                        <div v-for="(objectives, particular) in this.answerInitialOrder">
                            <h6>{{ this.particularTitle(particular) }}</h6>
                            <ul class="drag-ul ps-0 mb-4">
                                <draggable
                                    v-model="this.answerInitialOrder[particular]"
                                    item-key="index"
                                    @start="drag=true"
                                    @end="drag=false"
                                >
                                    <template #item="{element, index}">
                                        <li class="list-group-item-action d-flex drag-element justify-content-start align-items-center">
                                            <span class="badge bg-secondary rounded-pill">{{ index + 1 }}</span>
                                            <span class="ms-3">{{ question.particularQuestions[particular][element.index].text }}</span>
                                        </li>
                                    </template>
                                </draggable>
                            </ul>
                        </div>
                    </div>

                    <div v-if="question.category === questionsCategories.CATEGORY_AVAILABLE_RESOURCES">
                        <p>{{ question.text }}</p>
                        <p>{{ question.explanation }}</p>

                        <div class="my-4">
                            <p class="fs-5 lh-base" v-html="question.particularQuestions[0]"></p>
                            <textarea rows="5" class="form-control" v-model="answerAvailableResources"></textarea>
                        </div>
                    </div>

                    <div v-if="question.category === this.questionsCategories.CATEGORY_SMART_OBJECTIVE">
                        <div class="text-center mb-3">
                            <img src="/img/smart.png" class="img-fluid" alt="" style="max-width: 600px;">
                        </div>
                        <p>{{ question.text }}</p>
                        <table class="table table-striped table-hover my-5">
                            <thead>
                            <tr>
                                <th style="width: 20%;">Sigla</th>
                                <th style="width: 40%;">Descripción</th>
                                <th style="width: 40%;">Respuesta</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th class="align-middle">S (Específico)</th>
                                <td class="align-middle">¿Qué espera tener como logro global como resultado de realizar un plan de fortalecimiento enfocado en los objetivos identificados anteriormente?</td>
                                <td class="align-middle">
                                    <textarea v-model="answerSmartObjective.s" autofocus class="form-control" rows="5"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th class="align-middle">M (Medible)</th>
                                <td class="align-middle">¿Cómo sabrá que ha logrado la meta? ¿Qué medida utilizará para saber que se logró?</td>
                                <td class="align-middle">
                                    <textarea v-model="answerSmartObjective.m" class="form-control" rows="5"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th class="align-middle">A (Alcanzable)</th>
                                <td class="align-middle">¿Es una meta que su equipo podrá lograr, conforme a sus recursos y habilidades?</td>
                                <td class="align-middle">
                                    <textarea v-model="answerSmartObjective.a" class="form-control" rows="5"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th class="align-middle">R (Realista)</th>
                                <td class="align-middle">¿Es posible lograr esta meta en el tiempo que usted establece, con el equipo y los recursos que tiene a mano? De lo contrario, ¿cuáles ajustes realizará a la meta para que sea realista?</td>
                                <td class="align-middle">
                                    <textarea v-model="answerSmartObjective.r" class="form-control" rows="5"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th class="align-middle">T (Tiempo limitado)</th>
                                <td class="align-middle">¿Cuál es el tiempo límite de su meta?</td>
                                <td class="align-middle">
                                    <textarea v-model="answerSmartObjective.t" class="form-control" rows="5"></textarea>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <label class="form-label d-block mb-2 fw-bold">Meta final:</label>
                        <textarea v-model="answerSmartObjective.final" class="form-control mb-4" rows="5"></textarea>
                        <div class="alert alert-light shadow border-warning">
                            <p class="mb-2"><i class="la la-lightbulb text-warning me-1 fs-4"></i><strong>Ejemplo de Meta final</strong></p>
                            <p class="mb-0" style="line-height: 22px;"><small>"Nuestros currículos de lengua y matemáticas de K a 2do serán completados con objetivos - contenidos - estrategias de aprendizaje - criterias de evaluación - cosmovisión bíblica dentro del marco de los estándares de ACSI Latinoamerica al final del año escolar. Los currículos de 3ero a 6to serán completados igual para final del próximo curso escolar. Finalmente, los currículos de 7mo a 11mo serán completados al final del 2026."</small></p>
                        </div>
                    </div>

                </div>

                <div class="mt-5">
                    <div>
                        <div v-if="isLoading" class="d-flex justify-content-between">
                            <Skeletor width="20%" height="40px" style="border-radius: 10px;"/>
                            <Skeletor width="20%" height="40px" style="border-radius: 10px;"/>
                        </div>
                        <div v-else class="d-flex justify-content-between">
                            <button :disabled="isProcessing || question.index === 0"
                                    v-on:click="goBack"
                                    type="button"
                                    class="d-flex btn btn-light align-items-center justify-content-center">
                                <i class="icofont-arrow-left"></i> {{ __('survey.previous') }}
                            </button>
                            <button type="submit"
                                    :disabled="!isAnswerReady"
                                    class="d-flex btn btn-secondary align-items-center justify-content-center">
                                <span class="text-light">{{ __('survey.next') }}</span>
                                <i class="icofont-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="card-footer">
            <div class="small text-center"><i class="icofont-warning-alt"></i> {{ __('survey.bottom_message') }}
            </div>
        </div>
    </form>
</template>

<script>

import ProgressBar from "../ProgressBar.vue";
import {createToast as toast} from "mosha-vue-toastify";
import draggable from 'vuedraggable';

const CATEGORY_IDENTIFICATION = 'category_identification';
const CATEGORY_PARTICULARS_UNDERSTANDING = 'category_particulars_understanding';
const CATEGORY_ACHIEVED_WITHOUT_EVIDENCE = 'category_achieved_without_evidence';
const CATEGORY_CONFIRM_ACHIEVED_RESULTS = 'category_confirm_achieved_results';
const CATEGORY_INITIAL_ORDER = 'category_initial_order';
const CATEGORY_AVAILABLE_RESOURCES = 'category_available_resources';
const CATEGORY_SMART_OBJECTIVE = 'category_smart_objective';

export default {
    components: {draggable, ProgressBar},
    props: [
        'neighborhood',
        'api_token',
    ],
    data() {
        return {
            school: {},
            version: null,
            isLoading: true,
            isProcessing: false,
            progressPercentage: 0,
            question: null,
            answerIdentification: null,
            answerParticularsUnderstanding: [],
            answerAchievedWithoutEvidence: [],
            answerInitialOrder: [],
            answerAvailableResources: null,
            answerSmartObjective: {
                final: null,
                s: null,
                m: null,
                a: null,
                r: null,
                t: null,
            },
            errorMsg: null,
            questionsCategories: {
                CATEGORY_IDENTIFICATION: CATEGORY_IDENTIFICATION,
                CATEGORY_PARTICULARS_UNDERSTANDING: CATEGORY_PARTICULARS_UNDERSTANDING,
                CATEGORY_ACHIEVED_WITHOUT_EVIDENCE: CATEGORY_ACHIEVED_WITHOUT_EVIDENCE,
                CATEGORY_CONFIRM_ACHIEVED_RESULTS: CATEGORY_CONFIRM_ACHIEVED_RESULTS,
                CATEGORY_INITIAL_ORDER: CATEGORY_INITIAL_ORDER,
                CATEGORY_AVAILABLE_RESOURCES: CATEGORY_AVAILABLE_RESOURCES,
                CATEGORY_SMART_OBJECTIVE: CATEGORY_SMART_OBJECTIVE,
            },
            drag: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.axios.post('/api/survey/phase-3/start/GUIDE',
                {
                    neighborhood: this.neighborhood,
                })
                .then(response => {
                    if (!response.data.success) {
                        this.handleError(response.data.message);
                        this.isLoading = false;

                        return;
                    }
                    this.school = response.data.school;
                    this.progressPercentage = response.data.progressPercentage;
                    this.question = response.data.question;
                    this.parseAndSetAnswerByType(response.data.answer);
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.handleError(error.message);
                });
        },
        onSubmit(e) {
            this.errorMsg = null;
            e.preventDefault();

            this.isProcessing = true;
            this.axios.post('/api/survey/phase-3/answer/GUIDE',
                {
                    neighborhood: this.neighborhood,
                    question: this.question,
                    answer: this.getAnswerByType(),
                })
                .then(response => {
                    this.isProcessing = false;
                    if (!response.data.success) {
                        return this.handleError(response.data.message);
                    }

                    this.question = response.data.question;
                    this.parseAndSetAnswerByType(response.data.answer);
                    this.progressPercentage = response.data.progressPercentage;
                    this.scrollUp();
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.handleError(error.message);
                });

            this.resetAnswers();
        },
        goBack() {
            if (!confirm('Para regresar a la pregunta anterior tu respuesta actual será eliminada. ¿Estás seguro que deseas continuar?')) {
                return;
            }
            this.errorMsg = null;
            this.isProcessing = true;

            this.resetAnswers();

            this.axios.post('/api/survey/phase-3/go-back/GUIDE',
                {
                    neighborhood: this.neighborhood,
                    question_index: this.question.index,
                })
                .then(response => {
                    this.isProcessing = false;
                    if (!response.data.success) {
                        return this.handleError(response.data.message);
                    }

                    this.question = response.data.question;
                    this.parseAndSetAnswerByType(response.data.answer);
                    this.progressPercentage = response.data.progressPercentage;
                    this.scrollUp();
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.handleError(error.message);
                });
        },
        handleError(msg) {
            toast({
                title: 'Error',
                description: msg ?? 'An error occurred...',
            }, {
                type: 'danger',
            });
            this.scrollUp();
        },
        getAnswerByType() {
            return this[this.getTypeKey()];
        },
        parseAndSetAnswerByType(answer) {
            switch (this.question?.category) {
                case this.questionsCategories.CATEGORY_IDENTIFICATION:
                    this.answerIdentification = answer;
                    break;
                case this.questionsCategories.CATEGORY_PARTICULARS_UNDERSTANDING:
                    this.answerParticularsUnderstanding = answer ?? [];
                    break;
                case this.questionsCategories.CATEGORY_ACHIEVED_WITHOUT_EVIDENCE:
                    if (answer) {
                        this.answerAchievedWithoutEvidence = answer;
                        break;
                    }
                    let initialAnswer = {};
                    Object.keys(this.question.particularQuestions).forEach((particular) => {
                        initialAnswer[particular] = [];
                        Object.keys(this.question.particularQuestions[particular]).forEach(() => {
                            initialAnswer[particular].push(false);
                        });
                    });

                    this.answerAchievedWithoutEvidence = initialAnswer;
                    break;
                case this.questionsCategories.CATEGORY_INITIAL_ORDER:
                    if (answer) {
                        this.answerInitialOrder = answer;
                        break;
                    }
                    let buildModelAnswer = {};
                    Object.keys(this.question.particularQuestions).forEach((particular) => {
                        buildModelAnswer[particular] = [];
                        Object.keys(this.question.particularQuestions[particular]).forEach((key) => {
                            buildModelAnswer[particular].push({
                                index: key,
                            });
                        });
                    });

                    this.answerInitialOrder = buildModelAnswer;
                    break;
                case this.questionsCategories.CATEGORY_AVAILABLE_RESOURCES:
                    if (answer) {
                        this.answerAvailableResources = answer;
                    }
                    break;
                case this.questionsCategories.CATEGORY_SMART_OBJECTIVE:
                    if (answer) {
                        this.answerSmartObjective = answer;
                    }
                    break;
                default:
                    return null;
            }
        },
        getTypeKey() {
            switch (this.question?.category) {
                case this.questionsCategories.CATEGORY_IDENTIFICATION:
                    return 'answerIdentification';
                case this.questionsCategories.CATEGORY_PARTICULARS_UNDERSTANDING:
                    return 'answerParticularsUnderstanding';
                case this.questionsCategories.CATEGORY_ACHIEVED_WITHOUT_EVIDENCE:
                    return 'answerAchievedWithoutEvidence';
                case this.questionsCategories.CATEGORY_INITIAL_ORDER:
                    return 'answerInitialOrder';
                case this.questionsCategories.CATEGORY_AVAILABLE_RESOURCES:
                    return 'answerAvailableResources';
                case this.questionsCategories.CATEGORY_SMART_OBJECTIVE:
                    return 'answerSmartObjective';
                default:
                    return null;
            }
        },
        resetAnswers() {
            this.answerIdentification = null;
            this.answerParticularsUnderstanding = [];
            this.answerAchievedWithoutEvidence = [];
            this.answerInitialOrder = [];
            this.answerAvailableResources = null;
            this.answerSmartObjective = {
                final: null,
                s: null,
                m: null,
                a: null,
                r: null,
                t: null,
            };
        },
        scrollUp() {
            window.scrollTo(0, 0);
        },
        particularTitle(particular) {
            return this.__('survey.' + this.neighborhood + '.particular.' + particular + '.title');
        }
    },
    computed: {
        isAnswerReady() {
            switch (this.question?.category) {
                case this.questionsCategories.CATEGORY_IDENTIFICATION:
                    return this.answerIdentification !== null;
                case this.questionsCategories.CATEGORY_PARTICULARS_UNDERSTANDING:
                    const answeredQuestionsCount = this.answerParticularsUnderstanding.filter(answer => answer !== '').length;
                    const questionsToAnswerCount = this.question.particularQuestions.length;

                    return answeredQuestionsCount === questionsToAnswerCount;
                case this.questionsCategories.CATEGORY_ACHIEVED_WITHOUT_EVIDENCE:
                case this.questionsCategories.CATEGORY_CONFIRM_ACHIEVED_RESULTS:
                case this.questionsCategories.CATEGORY_INITIAL_ORDER:
                    return true;
                case this.questionsCategories.CATEGORY_AVAILABLE_RESOURCES:
                    return this.answerAvailableResources?.length > 0;
                case this.questionsCategories.CATEGORY_SMART_OBJECTIVE:
                    return this.answerSmartObjective.final !== null && this.answerSmartObjective.final !== ''
                        && this.answerSmartObjective.s !== null && this.answerSmartObjective.s !== ''
                        && this.answerSmartObjective.m !== null && this.answerSmartObjective.m !== ''
                        && this.answerSmartObjective.a !== null && this.answerSmartObjective.a !== ''
                        && this.answerSmartObjective.r !== null && this.answerSmartObjective.r !== ''
                        && this.answerSmartObjective.t !== null && this.answerSmartObjective.t !== '';
                default:
                    return false;
            }
        }
    },
}
</script>
