<template>
    <div class="progress progress-bar-survey">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" :aria-valuenow="this.progressPercentage" aria-valuemin="0" aria-valuemax="100" :style="{'width': this.progressPercentage + '%'}"></div>
    </div>
</template>

<script>

export default {
    props: [
        'progressPercentage',
    ]
}
</script>
