<template>
    <div v-if="isLoading" class="w-100 d-flex justify-content-center">
        <div :class="'spinner-border ' + getClasses" role="status">
            <span class="visually-hidden">{{ __('survey.loading_content') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'isLoading',
        'color',
        'size',
    ],
    computed: {
        getClasses() {
            let classes  = '';
            classes += this.color ? 'text-' + this.color : 'text-primary';
            classes += classes !== '' ? ' ' : '';
            classes += this.size ? 'spinner-border-' + this.size : '';

            return classes;
        },
    }
}
</script>