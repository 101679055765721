<template>
    <div class="card mb-3">
        <div class="card-body">
            <div v-if="!isLoading">
                <span class="fs-1">Total {{ results.score.percentage }}/100</span>
            </div>
            <div v-else class="d-flex justify-content-center align-items-center">
                <div><div class="spinner-border text-secondary"></div></div>
                <div><span class="fs-1 mb-0">/100</span></div>
            </div>
            <div class="progress mb-4" style="height: 10px;">
                <div :class="'progress-bar bg-' + results.score.bsColor" role="progressbar" :style="{width: results.score.percentage + '%' }" :aria-valuenow="results.score.percentage"
                     aria-valuemin="0" aria-valuemax="100"></div>
            </div>

            <div v-if="isLoading" class="py-3">
                <p class="mb-0 ms-2 text-secondary">{{ __('survey.loading_results') }}...</p>
            </div>
            <div v-else>
                <p class="font-weight-bold text-primary mb-0">{{ __('survey.result_details') }}</p>
                <div class="row">
                    <div v-for="(score, particular) in results.particulars" class="my-1 col-12 col-md-6">
                        <div v-if="score" class="mt-2">
                            <div class="pe-3 mb-1 mb-md-0">
                                <p class="mb-0 fs-4">{{ __('survey.' + neighborhood + '.particular.' + particular + '.title') }}</p>
                                <h4 class="mb-0">{{ score?.percentage ?? 0 }}/100</h4>
                            </div>
                            <div class="progress" style="height: 8px;">
                                <div :class="'progress-bar bg-' + (score?.bsColor ?? 'danger')" role="progressbar" :style="{width: (score?.percentage ?? 0) + '%'}" :aria-valuenow="score?.percentage ?? 0" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4 mb-3">
                    <div v-if="!sending && !alert && surveyType === SURVEY_TYPES.ADVANCED">
                        <button type="button" class="btn btn-outline-primary" :disabled="sending" @click="sendEmail">
                            <i class="icofont-envelope me-1"></i> {{ __('survey.send_by_email') }}
                        </button>
                    </div>
                    <div v-if="sending" class="py-2 d-flex justify-content-center align-items-center">
                        <div class="spinner-border" role="status"></div>
                        <p class="mb-0 ms-2 text-secondary">{{ __('survey.sending_results') }}...</p>
                    </div>
                    <div v-if="alert" :class="'mt-3 alert alert-' + alert.type">{{ alert.message }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {createToast as toast} from "mosha-vue-toastify";
export default {
    props: [
        'neighborhood',
        'surveyType',
    ],
    data() {
        return {
            results: {
                score: 0,
                particulars: null,
            },
            isLoading: true,
            sending: false,
            alert: null,
            SURVEY_TYPES: {
                BASIC: 'basic',
                ADVANCED: 'advanced',
            },
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.axios.post('/api/survey/phase-1/results/get',
                {
                    neighborhood: this.neighborhood,
                    surveyType: this.surveyType,
                })
                .then(response => {
                    if (!response.data.success) {
                        this.handleError(response.data.message);
                        this.isLoading = false;
                        return;
                    }
                    this.results = response.data.results;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.handleError(error.message);
                });
        },
        sendEmail() {
            this.sending = true;
            this.axios.post('/api/survey/phase-1/results/send',
                {
                    neighborhood: this.neighborhood,
                    surveyType: this.surveyType,
                })
                .then(response => {
                    if (!response.data.success) {
                        this.handleError(response.data.message);
                        this.sending = false;
                        return;
                    }
                    this.sending = false;

                    toast({
                        title: this.__('survey.done'),
                        description: response.data.message,
                    }, {
                        type: 'success',
                    });
                })
                .catch(error => {
                    this.sending = false;
                    this.handleError(error.message);
                });
        },
        handleError(msg) {
            toast({
                title: 'Error',
                description: msg ?? 'An error occurred...',
            }, {
                type: 'danger',
            });
        },
    },
}
</script>
