<template>
    <div class="card-body text-center p-5 fade-in-element">
        <i class="icofont-clock-time text-primary mb-3" style="font-size: 50px;"></i>
        <p class="h4 my-4">{{ __('survey.locked') }}</p>
        <a v-if="this.phase === 1" class="btn btn-secondary" :href="'/survey/' + neighborhood"><i class="icofont-arrow-left"></i> {{ __('general.back_to_surveys') }}</a>
        <a v-if="this.phase === 2" class="btn btn-secondary" href="/survey"><i class="icofont-arrow-left"></i> {{ __('general.back_to_surveys') }}</a>
    </div>
</template>

<script>
export default {
    props: [
        'neighborhood',
        'phase',
    ]
}
</script>