<template>
    <form class="card rounded-0 border-0 p-0" @submit="onSubmit">
        <div v-if="isTemporaryLocked">
            <temporary-locked :neighborhood="neighborhood" :phase="1"/>
        </div>
        <div v-else-if="isCompleted">
            <completed :neighborhood="neighborhood" :phase="1" :survey-type="surveyType" :school="school.id" :is-guest="isGuest"/>
        </div>
        <div v-else>
            <progress-bar :progress-percentage="progressPercentage"/>

            <div v-if="surveyType === SURVEY_TYPES.BASIC" class="alert alert-warning mx-4 mt-4 mb-0">
                {{ __('survey.basic_warning')}} <a :href="'/account/school/survey/phase-1/advanced/' + neighborhood">{{ __('survey.clicking_here')}}</a>.
            </div>

            <div class="d-lg-flex align-items-center">
                <div class="col-12 col-lg-8 text-center">
                    <div :class="'card-body p-5 ' + (surveyType === SURVEY_TYPES.BASIC ? 'pt-2' : '')">

                        <!-- loading skeleton-->
                        <div v-if="isLoading" class="py-3">
                            <Skeletor width="20%" style="border-radius: 10px;"/>
                            <div class="d-flex justify-content-center">
                                <Skeletor width="60%" height="30" style="border-radius: 10px;"/>
                            </div>
                            <Skeletor width="40%" style="border-radius: 10px;"/>
                            <Skeletor width="100%" height="100" class="mt-3" style="border-radius: 10px;"/>
                        </div>

                        <!-- school info header-->
                        <div v-else class="py-3">
                            <div class="py-2 text-b">
                                <p class="fs-4 mb-0">{{ __('admin.phase_1') }} <span class="text-muted">— {{ __('admin.survey_version') }}: {{ surveyName }}</span></p>
                                <h2 class="text-primary mb-2">{{ school.name }}</h2>
                                <p class="fs-4 mb-0 text-info">{{ __('survey.reference_key') }}: <small class="badge bg-info fs-6 ms-1">{{ question?.slug }}</small></p>
                                <p class="fs-1 lh-base mt-5 mb-3">{{ question?.text }}</p>
                            </div>
                        </div>

                        <!-- answer being processed-->
                        <div v-if="isProcessing" class="py-2 d-flex justify-content-center align-items-center">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">{{ __('survey.processing_answer') }}</span>
                            </div>
                            <p class="mb-0 ms-2 text-secondary">{{ __('survey.processing_answer') }}...</p>
                        </div>

                        <!-- question-->
                        <div v-else>
                            <!-- SHORT -->
                            <div v-if="question?.type === types.QUESTION_TYPE_SHORT">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div class="pretty p-icon p-curve p-pulse p-bigger">
                                        <input v-model="answerShort" type="radio" value="yes" ref="answer-short-yes"/>
                                        <div class="state p-success fs-4 fw-light">
                                            <i class="icon icofont-check"></i>
                                            <label><span class="p fs-2 text-muted">{{ __('profile.yes') }}</span></label>
                                        </div>
                                    </div>
                                    <span class="mx-4 me-5" style="border-right: #ededed solid 1px; height: 30px;"></span>
                                    <div class="pretty p-icon p-curve p-pulse p-bigger">
                                        <input v-model="answerShort" type="radio" value="no" ref="answer-short-no"/>
                                        <div class="state p-warning fs-4">
                                            <i class="icon icofont-close"></i>
                                            <label><span class="p fs-2 text-muted">{{ __('profile.no') }}</span></label>
                                        </div>
                                    </div>
                                    <span class="mx-4 me-5" style="border-right: #ededed solid 1px; height: 30px;"></span>
                                    <div class="pretty p-curve p-icon p-pulse p-bigger">
                                        <input v-model="answerShort" type="radio" value="none"  ref="answer-short-none"/>
                                        <div class="state p-danger fs-4">
                                            <i class="icon icofont-question-square"></i>
                                            <label><span class="p fs-2 text-muted">{{ __('profile.do_not_know') }}</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- TEXT -->
                            <div v-if="question?.type === types.QUESTION_TYPE_TEXT">
                                <textarea autofocus v-model="answerText" cols="30" rows="10" class="form-control"></textarea>
                            </div>

                            <!-- OPTION -->
                            <div v-if="question?.type === types.QUESTION_TYPE_OPTION">
                                <select v-model="answerOption" class="form-control">
                                    <option value="null" disabled selected> {{ __('general.select_an_answer') }}</option>
                                    <option v-for="(option, index) in question?.options" :value="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>

                        </div>

                        <div class="mt-5">
                            <div>
                                <div v-if="isLoading" class="d-flex justify-content-between">
                                    <Skeletor width="20%" height="40px" style="border-radius: 10px;"/>
                                    <Skeletor width="20%" height="40px" style="border-radius: 10px;"/>
                                </div>
                                <div v-else class="d-flex justify-content-between">
                                    <button :disabled="!canGoBack || isProcessing" v-on:click="goBack" type="button" class="d-flex btn btn-light align-items-center justify-content-center">
                                        <i class="icofont-arrow-left"></i> {{ __('survey.previous') }}
                                    </button>
                                    <button type="submit" :disabled="!isAnswerReady" class="d-flex btn btn-secondary align-items-center justify-content-center">
                                        <span class="text-light">{{ __('survey.next') }}</span>
                                        <i class="icofont-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-lg-4 mb-lg-0">
                    <div class="p-3 survey-sidebar">
                        <div v-if="isLoading">
                            <Skeletor width="50%" style="border-radius: 10px;"/>
                            <Skeletor width="100%" height="100" class="mt-3" style="border-radius: 10px;"/>
                        </div>
                        <div v-else class="p-3">
                            <h3 class="mb-1"> {{ __('survey.' + neighborhood + '.particular.' + particular + '.title') }}</h3>
                            <p class="fs-4 mb-1" v-html="$helpers.words(__('survey.' + neighborhood + '.particular.' + particular + '.description'), 50)"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="small text-center"><i class="icofont-warning-alt"></i> {{ __('survey.bottom_message') }}</div>
            </div>
        </div>
    </form>
</template>

<script>
import TemporaryLocked from "../TemporaryLocked";
import Completed from "../Completed";
import ProgressBar from "../ProgressBar";
const QUESTION_TYPE_SHORT = 'short';
const QUESTION_TYPE_OPTION = 'option';
const QUESTION_TYPE_TEXT = 'text';
import {createToast as toast} from "mosha-vue-toastify";
export default {
    components: {ProgressBar, Completed, TemporaryLocked},
    props: [
        'apiToken',
        'neighborhood',
        'surveyType',
        'isGuest'
    ],
    data() {
        return {
            surveyId: null,
            surveyName: null,
            level: null,
            neighborhoods: [],
            particulars: [],
            particular: null,
            school: {},
            progressPercentage: 0,
            questionsCount: 0,
            question: {},
            isCompleted: false,
            answerShort: null,
            answerText: null,
            answerOption: null,
            hasAnswerReady: false,
            canGoBack: false,
            isLoading: true,
            isProcessing: false,
            isTemporaryLocked: false,
            errorMsg: null,
            types: {
                QUESTION_TYPE_SHORT: QUESTION_TYPE_SHORT,
                QUESTION_TYPE_OPTION: QUESTION_TYPE_OPTION,
                QUESTION_TYPE_TEXT: QUESTION_TYPE_TEXT,
            },
            SURVEY_TYPES: {
                BASIC: 'basic',
                ADVANCED: 'advanced',
            }
        }
    },
    mounted() {
        this.init();
        const _this = this;
        window.addEventListener("keydown", function(event) {
            _this._handleKeyDown(event.code);
        });
    },
    methods: {
        init() {
            this.axios.post('/api/survey/phase-1/start',
                {
                    neighborhood: this.neighborhood,
                    surveyType: this.surveyType,
                    apiToken: this.apiToken,
                })
                .then(response => {
                    if (!response.data.success) {
                        this.handleError(response.data.message);
                        this.isLoading = false;
                        return;
                    }

                    this.surveyId = response.data.surveyId;
                    this.surveyName = response.data.surveyName;
                    this.level = response.data.level;
                    this.neighborhoods = response.data.neighborhoods;
                    this.particulars = response.data.particulars;
                    this.particular = response.data.particular;
                    this.school = response.data.school;
                    this.question = response.data.question;
                    this.canGoBack = response.data.canGoBack;
                    this.progressPercentage = response.data.progressPercentage;
                    this.questionsCount = response.data.questionsCount;
                    this.isCompleted = response.data.isCompleted;
                    this.isTemporaryLocked = response.data.isTemporaryLocked;
                    this.setAnswer(response.data.answer);

                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.handleError(error.message);
                });
        },
        onSubmit(e) {
            this.errorMsg = null;
            e.preventDefault();

            if (this.isProcessing || this.getCurrentAnswer() === null) {
                this.handleError('No answer provided.');
                this.isProcessing = false;

                return;
            }

            this.isProcessing = true;
            this.axios.post('/api/survey/phase-1/answer',
                {
                    question_id: this.question.id,
                    answer: this.getCurrentAnswer(),
                    survey_id: this.surveyId,
                    questions_count: this.questionsCount,
                    surveyType: this.surveyType,
                    apiToken: this.apiToken,
                })
                .then(response => {
                    this.isProcessing = false;
                    if (!response.data.success) {
                        return this.handleError(response.data.message);
                    }
                    this.loadNextAnswer(response.data);
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.handleError(error.message);
                });
        },
        goBack() {
            this.errorMsg = null;
            if (this.isProcessing) {
                return;
            }
            this.isProcessing = true;
            this.axios.post('/api/survey/phase-1/go-back',
                {
                    question_id: this.question.id,
                    questions_count: this.questionsCount,
                    surveyType: this.surveyType,
                    survey_id: this.surveyId,
                    apiToken: this.apiToken,
                })
                .then(response => {
                    this.isProcessing = false;
                    if (!response.data.success) {
                        this.canGoBack = false;
                        return this.handleError(response.data.message);
                    }
                    this.loadNextAnswer(response.data);
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.canGoBack = false;
                    this.handleError(error.message);
                });
        },
        getCurrentAnswer() {
            let answer;
            switch (this.question?.type) {
                case this.types.QUESTION_TYPE_SHORT:
                    answer = this.answerShort;
                    break;
                case this.types.QUESTION_TYPE_TEXT:
                    answer = this.answerText;
                    break;
                case this.types.QUESTION_TYPE_OPTION:
                    answer = this.answerOption;
                    break;
                default:
                    answer = null;
                    break;
            }

            return answer;
        },
        /**
         *
         * @param {Boolean} data.isCompleted
         * @param {String} data.particular
         * @param {Object} data.question
         * @param {Boolean} data.canGoBack
         * @param {Number} data.progressPercentage
         * @param {Number} data.level
         * @param {String|Null} data.answer
         */
        loadNextAnswer(data) {
            this.isCompleted = data.isCompleted;
            this.particular = data.particular;
            this.question = data.question;
            this.canGoBack = data.canGoBack;
            this.progressPercentage = data.progressPercentage;
            this.level = data.level;

            this.setAnswer(data.answer);
        },
        setAnswer(answer) {
            this.answerShort = null;
            this.answerText = null;
            this.answerOption = null;

            switch (this.question?.type) {
                case this.types.QUESTION_TYPE_SHORT:
                    this.answerShort = answer;
                    break;
                case this.types.QUESTION_TYPE_TEXT:
                    this.answerText = answer;
                    break;
                case this.types.QUESTION_TYPE_OPTION:
                    this.answerOption = answer;
                    break;
                default:
                    break;
            }
        },
        handleError (msg) {
            toast({
                title: 'Error',
                description: msg ?? 'An error occurred...',
            }, {
                type: 'danger',
            });
        },
        _handleKeyDown(code) {
            if (this.question?.type !== this.types.QUESTION_TYPE_SHORT || this.isLoading) {
                return;
            }
            switch (code) {
                case 'Digit1':
                    this.answerShort = 'yes';
                    this.$refs["answer-short-yes"].focus();
                    break;
                case 'Digit2':
                    this.answerShort = 'no';
                    this.$refs["answer-short-no"].focus();
                    break;
                case 'Digit3':
                    this.answerShort = 'none';
                    this.$refs["answer-short-none"].focus();
                    break;
            }
        }
    },
    computed: {
        isAnswerReady() {
            return !this.isProcessing && this.getCurrentAnswer() !== null;
        }
    },
}
</script>
