<template>
    <div class="card-body text-center p-5 fade-in-element">
        <i class="icofont-check text-white bg-success rounded-circle p-1" style="font-size: 50px;"></i>
        <h3 class="my-4">{{ __('survey.done') }}</h3>
        <p class="mt-2 mb-3">{{ __('survey.you_have_finished_neighbourhood_phase_' + phase) }}
            <strong>{{ __('survey.' + neighborhood + '.title') }}</strong>.
            <span v-if="isGuest == 0">
                <br>{{ __('survey.take_a_break') }}
            </span>
        </p>
        <div v-if="isGuest == 0">
            <a class="btn btn-secondary" :href="'/account/school/'+school+'/' + neighborhood"><i class="icofont-arrow-left me-1"></i>{{ __('general.back_to_surveys') }}</a>
            <a v-if="phase === 1" class="btn btn-primary ms-2" :href="'/account/school/survey-results/' + neighborhood + '/' + surveyType"><i class="icofont-chart-bar-graph me-1"></i> {{ __('general.see_results') }}</a>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        'neighborhood',
        'phase',
        'surveyType',
        'school',
        'isGuest'
    ]
}
</script>
